<template>
    <svg
      :width="width"
      :height="width"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_382_795)">
        <path d="M0 0H30V20H0V0Z" fill="white" />
        <path d="M0 0H30V10H0V0Z" fill="#C70000" />
      </g>
      <defs>
        <clipPath id="clip0_382_795">
          <rect :width="width" :height="height" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </template>
  
  <script>
  export default {
    name: "indonesia-flag",
    props: {
      width: {
        type: Number,
        default: 30,
      },
      height: {
        type: Number,
        default: 20,
      },
    },
  };
  </script>
  