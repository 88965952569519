<template>
  <div class="collapsed-arrow">
    <svg
      v-if="!on"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 8.22005C11.7986 8.22184 11.6055 8.30054 11.4601 8.44005L5.43011 14.5001C5.35643 14.5687 5.29733 14.6515 5.25633 14.7435C5.21534 14.8355 5.1933 14.9348 5.19152 15.0355C5.18975 15.1362 5.20827 15.2363 5.24599 15.3296C5.28371 15.423 5.33986 15.5079 5.41108 15.5791C5.48229 15.6503 5.56713 15.7065 5.66052 15.7442C5.7539 15.7819 5.85393 15.8004 5.95464 15.7986C6.05534 15.7969 6.15465 15.7748 6.24665 15.7338C6.33865 15.6928 6.42145 15.6337 6.49011 15.5601L12.0001 10.0001L17.5001 15.5201C17.6423 15.6525 17.8303 15.7247 18.0246 15.7212C18.2189 15.7178 18.4043 15.6391 18.5417 15.5017C18.6791 15.3643 18.7579 15.1789 18.7613 14.9846C18.7647 14.7903 18.6926 14.6022 18.5601 14.4601L12.5601 8.40005C12.4046 8.26831 12.2032 8.20358 12.0001 8.22005Z"
        fill="#36373F"
      />
    </svg>

    <svg
      v-else
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 15.78C11.7986 15.7782 11.6055 15.6995 11.4601 15.56L5.43011 9.5C5.35643 9.43134 5.29733 9.34854 5.25633 9.25654C5.21534 9.16454 5.1933 9.06523 5.19152 8.96452C5.18975 8.86382 5.20827 8.76379 5.24599 8.6704C5.28371 8.57702 5.33986 8.49218 5.41108 8.42096C5.48229 8.34974 5.56713 8.2936 5.66052 8.25588C5.7539 8.21816 5.85393 8.19963 5.95464 8.20141C6.05534 8.20319 6.15465 8.22523 6.24665 8.26622C6.33865 8.30721 6.42145 8.36632 6.49011 8.44L12.0001 14L17.5001 8.48C17.6421 8.37454 17.8168 8.32263 17.9933 8.33347C18.1698 8.3443 18.3368 8.41716 18.4649 8.5392C18.5929 8.66123 18.6736 8.82457 18.6929 9.00038C18.7122 9.17619 18.6687 9.35315 18.5701 9.5L12.5701 15.56C12.4951 15.6336 12.4059 15.6911 12.3079 15.729C12.2098 15.7668 12.1051 15.7842 12.0001 15.78Z"
        fill="#36373F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    on: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.collapsed-arrow {
  cursor: pointer;
}
</style>
